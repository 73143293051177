export function Ctasection() {
    return (
        <section className="py-14">
            <div className="max-w-screen-xl mx-auto px-8 md:px-8">
                <div className="flex items-center gap-x-12 px-4 md:px-0 lg:flex">
                    <div className="flex-1 hidden lg:block">
                        <img src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" 
                            className="rounded-lg md:max-w-lg" alt="Professional service" />
                    </div>
                    <div className="max-w-xl mt-6 md:mt-0 lg:max-w-2xl">
                        <h3 className="text-indigo-600 font-semibold">
                            My Care Billing
                        </h3>
                        <p className="text-gray-800 text-3xl font-semibold mt-3 sm:text-4xl">
                        My care Billing our experts solutions are here
                        </p>
                        <p className="mt-3 text-gray-600">
                        Mycare Billing India is one of the fast-growing medical billing companies in India that provides complete medical billing solutions and coding services.        </p>
                        <p className="mt-3 text-gray-600">
                        Mycare Billing with its services, that it works with clients of all sizes – no client is too small or too large and they all get the same level of attention.     </p>
                        <a href="#" className="inline-flex items-center gap-x-1 text-indigo-600 hover:text-indigo-500 duration-150 font-medium">
                            Learn more
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                <path fillRule="evenodd" d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z" clipRule="evenodd" />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

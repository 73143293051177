import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const ShuffleHero1 = () => {
  const navigate = useNavigate();

  const handleBooking = () => {
    navigate("/billing-services");
  };

  return (
    <section className="w-full px-8 py-12 grid grid-cols-1 md:grid-cols-2 items-center gap-8 max-w-6xl mx-auto">
      <div>
        <h3 className="text-4xl md:text-6xl font-semibold text-black">
          My Care Billing
        </h3>
        <p className="text-base md:text-lg text-slate-700 my-4 md:my-6">
          My Care Billing offers comprehensive medical billing services that
          ensure accuracy, compliance, and timely reimbursements. Whether you
          are a small practice or a large healthcare provider, we have the
          solutions you need to manage your revenue cycle efficiently.
        </p>
        <button
          className="bg-indigo-500 text-white font-medium py-2 px-4 rounded transition-all hover:bg-indigo-600 active:scale-95"
          onClick={handleBooking}
        >
          Explore Services
        </button>
        <p className="text-xs md:text-sm font-medium mt-4 text-left">
          <span className="text-orange-500">NOTE: </span>
          <span className="text-black">
            Ensure that all patient billing information is accurate and up to
            date to prevent delays in reimbursement. Please consult with our
            billing specialists for any questions regarding the submission of
            claims or the management of accounts.
          </span>
        </p>
      </div>
      <ShuffleGrid />
    </section>
  );
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const squareData = [
  {
    id: 1,
    src: "https://i.ibb.co/xJchwbz/13.png",
  },
  {
    id: 2,
    src: "https://i.ibb.co/KxnQSV2/14.png",
  },
  {
    id: 3,
    src: "https://i.ibb.co/KxnQSV2/14.png",
  },
  {
    id: 4,
    src: "https://i.ibb.co/kMc1sB5/17.png",
  },
  {
    id: 5,
    src: "https://i.ibb.co/RjML52c/18.png",
  },
  {
    id: 6,
    src: "https://i.ibb.co/7ybmtT8/19.png",
  },
  {
    id: 7,
    src: "https://i.ibb.co/S0Wc2DW/20.png",
  },
  {
    id: 8,
    src: "https://i.ibb.co/k2C3917/21.png",
  },
  {
    id: 9,
    src: "https://img.freepik.com/free-vector/billing-invoice-form-concept_23-2147822674.jpg?w=1800",
  },
  {
    id: 10,
    src: "https://img.freepik.com/free-vector/doctor-insurance-form-billing-concept_23-2147822686.jpg?w=1800",
  },
  {
    id: 11,
    src: "https://img.freepik.com/free-vector/insurance-claim-billing-form_23-2147822672.jpg?w=1800",
  },
  {
    id: 12,
    src: "https://img.freepik.com/free-vector/medical-claim-billing-insurance-form_23-2147822684.jpg?w=1800",
  },
  {
    id: 13,
    src: "https://img.freepik.com/free-vector/doctor-filling-medical-insurance-billing-form_23-2147822694.jpg?w=1800",
  },
  {
    id: 14,
    src: "https://img.freepik.com/free-vector/billing-invoice-medical-insurance-concept_23-2147822680.jpg?w=1800",
  },
  {
    id: 15,
    src: "https://img.freepik.com/free-vector/medical-insurance-billing-form_23-2147822678.jpg?w=1800",
  },
  {
    id: 16,
    src: "https://img.freepik.com/free-vector/insurance-claim-form-medical-billing_23-2147822690.jpg?w=1800",
  },
];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "spring" }}
      className="w-full h-full"
      style={{
        backgroundImage: `url(${sq.src})`,
        backgroundSize: "cover",
      }}
    ></motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="grid grid-cols-4 grid-rows-4 h-[450px] gap-1">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default ShuffleHero1;

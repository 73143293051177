import React from 'react'
import { Sampletest } from '../components/AboutUs/Sampletest'
import { Index } from '../components/AboutUs/Index'
import Sample from '../components/AboutUs/Sample'
import ContactForm from '../components/AboutUs/ContactForm'
import Values from '../components/AboutUs/Values'
import Team from '../components/AboutUs/Team'
import CoreValues from '../components/AboutUs/CoreValues'

const About = () => {
  return (
    <div>
      <br></br>
        <Index/>
<Team/>
 {/* <Sampletest/>  */}
 <Sample/>
 <CoreValues/>
 <ContactForm/>
 {/* <Values/> */}
    </div>
  )
}

export default About

import React from 'react';
import Example from './card';
import CredentialCard from './card';
import { Supports } from '../Options/Supports';
import { FlyoutNav } from '../Navbar/FlyoutNavbar';
import { ImageTrailHero } from '../Herosection/ImageTrailHero';
import { Hero2 } from '../Herosection/Hero2';
import AccordionCredential from './Accordion';



function Credentialing() {
  

  return (<>
    <div>
      <FlyoutNav/>
      <Hero2/>
      <Supports/>
      <AccordionCredential/>
      <CredentialCard/>
    </div>
      
      </>
  );
}

export default Credentialing;

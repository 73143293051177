import React from 'react'
// import MedicalBilling from '../Billing/MedicalBilling'
import CollapseCardFeatures from './BillingAudit'
import Hero from '../Herosection/Hero'
import CardCarousel from '../Card/CardCarousel'
import VerticalSlideFeatures from './VerticalSlideFeatures'

const Billing = () => {
  return (
    <div>
        <div className="conatainer">
        <Hero/>
            
        </div>

      <CollapseCardFeatures/>
      <VerticalSlideFeatures/>
      <CardCarousel/>
    </div>
  )
}

export default Billing

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
// import Home from './Home';
// import About from './About';
import logo from './logo.svg';
import './App.css';
import Example, { FlyoutNav } from './components/Navbar/FlyoutNavbar';
import Home from './pages/Home';
import Footer from './components/Footer/Footer';
import MedicalBilling from './components/Billing/MedicalBilling';
import Credentialing from './components/Credential/Credentialing';
import MedicalCoding from './components/Coding/MedicalCoding';
import Arm from './components/ARM/Arm';
import DenialManagement from './components/Denial/DenialManagement';
import CollapseCardFeatures from './components/Audit/BillingAudit';
import Billing from './components/Audit/Billing';
import About from './pages/About';

function HomeWithExample() {
  const location = useLocation();

  return (
    <>
      {/* Render Example only on the home route */}
      {/* {location.pathname === '/' && <Example />} */}
      <Home />
    </>
  );
}

function App() {
  return (
    <Router>
      <FlyoutNav/>
      <Routes>        
        <Route path="/" element={<HomeWithExample />} />
        <Route path="/aboutUs" element={<About />} />
        <Route path='/medicalBilling' element={<MedicalBilling/>}/>
        <Route path='/credential' element={<Credentialing/>}/>
        <Route path='/coding' element={<MedicalCoding/>}/>
        <Route path='/audit' element={<Billing/>}/>
        <Route path='/arm' element={<Arm/>}/>
        <Route path='/denial' element={<DenialManagement/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
import { useAnimate } from "framer-motion";
import React, { useRef } from "react";
import { motion } from "framer-motion";
import { FiArrowDownCircle, FiDollarSign } from "react-icons/fi";
import { SiApple } from "react-icons/si";

export const ImageTrailHero = () => {
  return (
    <MouseImageTrail
      renderImageBuffer={50}
      rotationRange={25}
      images={[
        "https://i.ibb.co/dtMZWwT/8.png",
        "https://i.ibb.co/Bg2Rs0c/9.png",
        "https://i.ibb.co/hCVMXXb/10.png",
        "https://i.ibb.co/qD3TDkg/11.png",
        "https://i.ibb.co/rmZ9bbT/12.png",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_Rtn6aAU4qSAYnbhv9EFRk5xi-cPNfByTSA&s",
      ]}
    >
      <section className="h-screen bg-slate-200">
        <Copy />
        <WatermarkWrapper />
      </section>
    </MouseImageTrail>
  );
};

const NavBar = () => {
  return (
    <nav className="absolute left-0 right-0 top-0 z-[99999999]">
      <div className="bg-slate-900 text-center">
        <p className="flex items-center justify-center gap-1 py-0.5 text-sm font-medium uppercase text-slate-100">
          <FiDollarSign className="text-indigo-300" />
          <span>20% off through December on ARM Management Services</span>
          <FiDollarSign className="text-indigo-300" />
        </p>
      </div>
      <div className="mx-auto flex max-w-7xl items-center justify-between p-4 md:p-6">
        <svg
          width="50"
          height="39"
          viewBox="0 0 50 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="relative z-10 fill-slate-900"
        >
          <path
            d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z"
            stopColor="#000000"
          ></path>
          <path
            d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z"
            stopColor="#000000"
          ></path>
        </svg>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.995, rotate: "3.5deg" }}
          className="flex items-center gap-2 rounded-md bg-indigo-500 px-4 py-2 font-medium text-slate-50 transition-colors hover:bg-indigo-600"
        >
          <SiApple />
          <span>Download ARM App</span>
        </motion.button>
      </div>
    </nav>
  );
};

const Copy = () => {
  return (
    <div className="absolute bottom-0 left-0 right-0 z-[999]">
      <div className="mx-auto flex max-w-7xl items-end justify-center md:justify-between p-0 md:p-8">
        <div>
          <h1 className="mb-6 max-w-4xl font-black leading-[1.1] text-slate-900 md:text-7xl">
            Advanced <span className="text-indigo-500">ARM Management</span> Solutions
          </h1>
          <p className="max-w-xl mb-2 text-slate-700 text-sm md:text-lg">
            ARM (Account Receivable Management) is critical in ensuring the financial health of your business. 
            Our advanced ARM management solutions help you effectively manage outstanding invoices, enhance cash flow, 
            and maintain strong client relationships.
          </p>
        </div>
        <FiArrowDownCircle className="hidden text-8xl text-slate-500 md:block" />
      </div>
    </div>
  );
};

const WatermarkWrapper = () => {
  return (
    <>
      <Watermark text="ARM Management" />
      <Watermark text="Optimize Cash Flow" reverse />
      <Watermark text="Boost Efficiency" />
      <Watermark text="Client Relationships" reverse />
      <Watermark text="ARM Management" />
      <Watermark text="Optimize Cash Flow" reverse />
      <Watermark text="Boost Efficiency" />
      <Watermark text="Client Relationships" reverse />
    </>
  );
};

const Watermark = ({ reverse, text }) => (
  <div className="flex -translate-y-12 select-none overflow-hidden">
    <TranslateWrapper reverse={reverse}>
      <span className="w-fit whitespace-nowrap text-[20vmax] font-black uppercase leading-[0.75] text-slate-300">
        {text}
      </span>
    </TranslateWrapper>
    <TranslateWrapper reverse={reverse}>
      <span className="ml-48 w-fit whitespace-nowrap text-[20vmax] font-black uppercase leading-[0.75] text-slate-300">
        {text}
      </span>
    </TranslateWrapper>
  </div>
);

const TranslateWrapper = ({ children, reverse }) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? "-100%" : "0%" }}
      animate={{ translateX: reverse ? "0%" : "-100%" }}
      transition={{ duration: 75, repeat: Infinity, ease: "linear" }}
      className="flex"
    >
      {children}
    </motion.div>
  );
};

const MouseImageTrail = ({
  children,
  images,
  renderImageBuffer,
  rotationRange,
}) => {
  const [scope, animate] = useAnimate();

  const lastRenderPosition = useRef({ x: 0, y: 0 });
  const imageRenderCount = useRef(0);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;

    const distance = calculateDistance(
      clientX,
      clientY,
      lastRenderPosition.current.x,
      lastRenderPosition.current.y
    );

    if (distance >= renderImageBuffer) {
      lastRenderPosition.current.x = clientX;
      lastRenderPosition.current.y = clientY;

      renderNextImage();
    }
  };

  const calculateDistance = (x1, y1, x2, y2) => {
    const deltaX = x2 - x1;
    const deltaY = y2 - y1;

    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    return distance;
  };

  const renderNextImage = () => {
    const imageIndex = imageRenderCount.current % images.length;
    const selector = `[data-mouse-move-index="${imageIndex}"]`;

    const el = document.querySelector(selector);

    el.style.top = `${lastRenderPosition.current.y}px`;
    el.style.left = `${lastRenderPosition.current.x}px`;
    el.style.zIndex = imageRenderCount.current.toString();

    const rotation = Math.random() * rotationRange;

    animate(
      selector,
      {
        opacity: [0, 1],
        transform: [
          `translate(-50%, -25%) scale(0.5) ${
            imageIndex % 2
              ? `rotate(${rotation}deg)`
              : `rotate(-${rotation}deg)`
          }`,
          `translate(-50%, -50%) scale(1) ${
            imageIndex % 2
              ? `rotate(-${rotation}deg)`
              : `rotate(${rotation}deg)`
          }`,
        ],
      },
      { type: "spring", damping: 15, stiffness: 200 }
    );

    animate(
      selector,
      {
        opacity: [1, 0],
      },
      { ease: "linear", duration: 0.5, delay: 1 }
    );

    imageRenderCount.current = imageRenderCount.current + 1;
  };

  return (
    <div
      ref={scope}
      className="relative overflow-hidden"
      onMouseMove={handleMouseMove}
    >
      {children}

      {images.map((img, index) => (
        <img
          className="pointer-events-none absolute left-0 top-0 h-36 w-auto rounded-xl border-2 border-slate-900 bg-slate-800 object-cover opacity-0"
          src={img}
          alt={`Mouse move image ${index}`}
          key={index}
          data-mouse-move-index={index}
        />
      ))}
    </div>
  );
};

import { motion, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FiDollarSign, FiEye, FiPlay, FiSearch } from "react-icons/fi";

const SwapColumnFeatures = () => {
    const [featureInView, setFeatureInView] = useState(features[0]);
  
    return (
      <section className="relative mx-auto max-w-7xl">
        <SlidingFeatureDisplay featureInView={featureInView} />
  
        {/* Offsets the height of SlidingFeatureDisplay so that it renders on top of Content to start */}
        <div className="-mt-[100vh] hidden md:block" />
  
        {features.map((s) => (
          <Content
            key={s.id}
            featureInView={s}
            setFeatureInView={setFeatureInView}
            {...s}
          />
        ))}
      </section>
    );
  };
  
  const SlidingFeatureDisplay = ({ featureInView }) => {
    return (
      <div
        style={{
          justifyContent:
            featureInView.contentPosition === "l" ? "flex-end" : "flex-start",
        }}
        className="pointer-events-none sticky top-0 z-10 hidden h-screen w-full items-center justify-center md:flex"
      >
        <motion.div
          layout
          transition={{
            type: "spring",
            stiffness: 400,
            damping: 25,
          }}
          className="h-fit w-3/5 rounded-xl p-8"
        >
          <ExampleFeature featureInView={featureInView} />
        </motion.div>
      </div>
    );
  };
  
  const Content = ({ setFeatureInView, featureInView }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {
      margin: "-150px",
    });
  
    useEffect(() => {
      if (isInView) {
        setFeatureInView(featureInView);
      }
    }, [isInView]);
  
    return (
      <section
        ref={ref}
        className="relative z-0 flex h-fit md:h-screen"
        style={{
          justifyContent:
            featureInView.contentPosition === "l" ? "flex-start" : "flex-end",
        }}
      >
        <div className="grid h-full w-full place-content-center px-4 py-12 md:w-2/5 md:px-8 md:py-8">
          <motion.div
            initial={{ opacity: 0, y: 25 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <span className="rounded-full bg-indigo-600 px-2 py-1.5 text-xs font-medium text-white">
              {featureInView.callout}
            </span>
            <p className="my-3 text-5xl font-bold">{featureInView.title}</p>
            <p className="text-slate-600">{featureInView.description}</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 25 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="mt-8 block md:hidden"
          >
            <ExampleFeature featureInView={featureInView} />
          </motion.div>
        </div>
      </section>
    );
  };
  
  const ExampleFeature = ({ featureInView }) => {
    return (
      <div className="relative h-96 w-full rounded-xl bg-slate-800 shadow-xl">
        <div className="flex w-full gap-1.5 rounded-t-xl bg-slate-900 p-3">
          <div className="h-3 w-3 rounded-full bg-red-500" />
          <div className="h-3 w-3 rounded-full bg-yellow-500" />
          <div className="h-3 w-3 rounded-full bg-green-500" />
        </div>
        <div className="p-2">
          <p className="font-mono text-sm text-slate-200">
            <span className="text-green-300">~</span> Show a part of your product
            that explains what{" "}
            <span className="inline-block rounded bg-indigo-600 px-1 font-semibold">
              "{featureInView.title}"
            </span>{" "}
            means.
          </p>
        </div>
  
        <img
          src={featureInView.imageUrl}
          alt={featureInView.title}
          className="absolute left-[50%] top-[50%] h-full w-full object-cover -translate-x-[50%] -translate-y-[50%]"
        />
      </div>
    );
  };
  
  export default SwapColumnFeatures;
  const features = [
    {
      id: 1,
      callout: "Efficient Processes",
      title: "Streamlined Billing Procedures",
      description:
        "Our medical billing services are designed to streamline the billing process, reduce errors, and ensure timely payments. By automating and refining billing procedures, we help healthcare providers focus on patient care rather than administrative tasks.",
      contentPosition: "r",
      imageUrl: "https://i.ibb.co/dtMZWwT/8.png", // Replace with your image URL
    },
    {
      id: 2,
      callout: "Compliance Assurance",
      title: "Accurate and Compliant Billing",
      description:
        "We ensure that all billing processes comply with the latest healthcare regulations and standards. Our team stays updated on industry changes, ensuring that your billing is accurate and compliant, reducing the risk of audits and penalties.",
      contentPosition: "l",
      imageUrl: "https://i.ibb.co/Bg2Rs0c/9.png", // Replace with your image URL
    },
    {
      id: 3,
      callout: "Dedicated Support",
      title: "Personalized Billing Solutions",
      description:
        "Our personalized billing solutions are tailored to meet the unique needs of your practice. With dedicated support and customized services, we help you optimize your revenue cycle management and improve financial outcomes.",
      contentPosition: "r",
      imageUrl: "https://i.ibb.co/hCVMXXb/10.png", // Replace with your image URL
    },
  ];
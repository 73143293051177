import { motion } from "framer-motion";
import { useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import useMeasure from "react-use-measure";

const CARD_WIDTH = 350;
const CARD_HEIGHT = 350;
const MARGIN = 20;
const CARD_SIZE = CARD_WIDTH + MARGIN;

const BREAKPOINTS = {
  sm: 640,
  lg: 1024,
};

const CardCarousel = () => {
  const [ref, { width }] = useMeasure();
  const [offset, setOffset] = useState(0);

  const CARD_BUFFER =
    width > BREAKPOINTS.lg ? 3 : width > BREAKPOINTS.sm ? 2 : 1;

  const CAN_SHIFT_LEFT = offset < 0;

  const CAN_SHIFT_RIGHT =
    Math.abs(offset) < CARD_SIZE * (items.length - CARD_BUFFER);

  const shiftLeft = () => {
    if (!CAN_SHIFT_LEFT) {
      return;
    }
    setOffset((pv) => (pv += CARD_SIZE));
  };

  const shiftRight = () => {
    if (!CAN_SHIFT_RIGHT) {
      return;
    }
    setOffset((pv) => (pv -= CARD_SIZE));
  };

  return (
    <section className="bg-slate-100" ref={ref}>
      <div className="relative overflow-hidden p-4">
        {/* CARDS */}
        <div className="mx-auto max-w-6xl">
          <p className="mb-4 text-2xl font-semibold">
            Everything. <span className="text-slate-500">Yes, even that.</span>
          </p>
          <motion.div
            animate={{
              x: offset,
            }}
            className="flex"
          >
            {items.map((item) => {
              return <Card key={item.id} {...item} />;
            })}
          </motion.div>
        </div>

        {/* BUTTONS */}
        <>
          <motion.button
            initial={false}
            animate={{
              x: CAN_SHIFT_LEFT ? "0%" : "-100%",
            }}
            className="absolute left-0 top-[60%] z-30 rounded-r-xl bg-slate-100/30 p-3 pl-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pl-3"
            onClick={shiftLeft}
          >
            <FiChevronLeft />
          </motion.button>
          <motion.button
            initial={false}
            animate={{
              x: CAN_SHIFT_RIGHT ? "0%" : "100%",
            }}
            className="absolute right-0 top-[60%] z-30 rounded-l-xl bg-slate-100/30 p-3 pr-2 text-4xl text-white backdrop-blur-sm transition-[padding] hover:pr-3"
            onClick={shiftRight}
          >
            <FiChevronRight />
          </motion.button>
        </>
      </div>
    </section>
  );
};

const Card = ({ url, category, title, description }) => {
  return (
    <div
      className="relative shrink-0 cursor-pointer rounded-2xl bg-white shadow-md transition-all hover:scale-[1.015] hover:shadow-xl"
      style={{
        width: CARD_WIDTH,
        height: CARD_HEIGHT,
        marginRight: MARGIN,
        backgroundImage: `url(${url})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        
      }}
    >
      <div className="absolute inset-0 z-20 rounded-2xl bg-gradient-to-b from-black/70 via-black/60 to-black/0 p-6 text-white transition-[backdrop-filter] hover:backdrop-blur-sm">
        <span className="text-xs font-semibold uppercase text-violet-300">
          {category}
        </span>
        <p className="my-2 text-3xl font-bold">{title}</p>
        <p className="text-lg text-slate-300">{description}</p>
      </div>
    </div>
  );
};

export default CardCarousel;


const items = [
    {
      id: 1,
      url: "https://i.ibb.co/cLxzC80/3.gif", // replace with your image URL
      category: "Medical Billing",
      title: "Medical Billing",
      description: "Efficient claims processing and revenue collection.",
      navigateTo: "/medical-billing",
    },
    {
      id: 2,
      url: "https://i.ibb.co/pXkJ1dD/1.gif", // replace with your image URL
      category: "Medical Coding",
      title: "Medical Coding",
      description: "Accurate coding for optimized reimbursements.",
      navigateTo: "/medical-coding",
    },
    {
      id: 3,
      url: "https://i.ibb.co/gzYw7wJ/4.gif", // replace with your image URL
      category: "Credentialing",
      title: "Credentialing",
      description: "Provider compliance and insurance eligibility.",
      navigateTo: "/credentialing",
    },
    {
      id: 4,
      url: "https://i.ibb.co/VHVbKDP/5.gif", // replace with your image URL
      category: "Billing Audit",
      title: "Billing Audit",
      description: "Detailed review for billing accuracy and compliance.",
      navigateTo: "/billing-audit",
    },
    {
      id: 5,
      url: "https://i.ibb.co/82pm87x/2.gif", // replace with your image URL
      category: "ARM",
      title: "ARM",
      description: "Complete accounts receivable management services.",
      navigateTo: "/arm",
    },
    {
      id: 6,
      url: "https://i.ibb.co/jwZYM7r/6.gif", // replace with your image URL
      category: "Denial Management",
      title: "Denial Management",
      description: "Resolving claim denials to maximize revenue.",
      navigateTo: "/denial-management",
    },
  ];
  
import React, { useEffect, useRef } from "react";
import { animate, useInView } from "framer-motion";

export function Discription() {
  // Define the target numbers
  const targetNumbers = {
    patientEncounters: 20278232,
    patientInteractions: 100000000,
    providers: 152730,
    healthcareProfessionals: 150000,
    facilities: 3299,
    medicalCenters: 4000,
  };

  // Component to render individual stats with counting animation
  const Stat = ({ target, suffix = "" }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    useEffect(() => {
      if (!isInView) return;

      // Use framer-motion's animate function to count up to the target number
      animate(0, target, {
        duration: 2.5,
        onUpdate(value) {
          if (ref.current) {
            ref.current.textContent = value.toLocaleString() + suffix;
          }
        },
      });
    }, [isInView, target, suffix]);

    return <strong ref={ref} className="text-indigo-600 text-2xl"></strong>;
  };

  return (
    <>
      <section className="mt-24 mx-auto max-w-screen-xl pb-4 px-4 items-center lg:flex md:px-8">
        <div className="space-y-4 flex-1 sm:text-center lg:text-left">
          <h1 className="text-gray-800 font-bold text-4xl xl:text-5xl">
            My Care Billing has automated
            <span className="text-indigo-600"> workflows for</span>
          </h1>
          <p className="text-gray-800 text-xl pt-6">
            <Stat target={targetNumbers.patientEncounters} suffix="+" /> patient
            encounters
          </p>
          <p className="text-gray-800 text-xl">
            Over{" "}
            <Stat target={targetNumbers.patientInteractions} suffix="+" />{" "}
            patient interactions
          </p>
          <p className="text-gray-800 text-xl">
            <Stat target={targetNumbers.providers} /> providers
          </p>
          <p className="text-gray-800 text-xl">
            <Stat
              target={targetNumbers.healthcareProfessionals}
              suffix="+"
            />{" "}
            healthcare professionals
          </p>
          <p className="text-gray-800 text-xl">
            <Stat target={targetNumbers.facilities} /> facilities
          </p>
          <p className="text-gray-800 text-xl">
            More than <Stat target={targetNumbers.medicalCenters} /> medical
            centers
          </p>
          <div>
            {/* Form commented out for this example */}
            {/* <form className="items-center space-y-3 sm:justify-center sm:space-x-3 sm:space-y-0 sm:flex lg:justify-start">
              <input
                type="text"
                placeholder="Enter your email"
                className="text-gray-500 border outline-none p-3 rounded-md w-full sm:w-72"
              />
              <button className="outline-none bg-gray-700 text-white text-center px-4 py-3 rounded-md shadow w-full ring-offset-2 ring-gray-700 focus:ring-2  sm:w-auto">
                Subscribe
              </button>
            </form> */}
          </div>
        </div>
        <div className="flex-1 text-center mt-4 lg:mt-0 lg:ml-3">
          <img
            src="https://i.postimg.cc/kgd4WhyS/container.png"
            className="w-full mx-auto sm:w-10/12 lg:w-full"
          />
        </div>
      </section>
    </>
  );
}

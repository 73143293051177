import { MotionConfig, motion } from "framer-motion";

const Testimonial = ({ imgSrc, name, title, company, content }) => (
  <MotionConfig
    transition={{
      duration: 0.2,
      ease: "easeInOut",
    }}
  >
    <motion.div
      initial={{
        y: 0,
      }}
      animate={{
        y: -8,
      }}
      exit={{
        y: 0,
      }}
      className="w-full overflow-hidden rounded-lg border-2 border-zinc-900 bg-white p-8 md:p-12"
    >
      <div className="mb-6 flex items-center gap-6">
        <div className="rounded-lg bg-zinc-900">
          <motion.img
            initial={{
              rotate: "0deg",
              opacity: 0,
            }}
            animate={{
              rotate: "3deg",
              opacity: 1,
            }}
            exit={{
              rotate: "0deg",
              opacity: 0,
            }}
            src={imgSrc}
            alt="avatar"
            className="size-24 rounded-lg border-2 border-zinc-900 bg-indigo-200"
          />
        </div>
        <motion.div
          initial={{
            y: 12,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          exit={{
            y: -12,
            opacity: 0,
          }}
        >
          <span className="mb-1.5 block text-3xl font-medium">{name}</span>
          <span className="text-zinc-600">
            {title} – <span className="text-indigo-600">{company}</span>
          </span>
        </motion.div>
      </div>
      <motion.p
        initial={{
          y: 12,
          opacity: 0,
        }}
        animate={{
          y: 0,
          opacity: 1,
        }}
        exit={{
          y: -12,
          opacity: 0,
        }}
        className="text-xl leading-relaxed"
      >
        {content}
      </motion.p>
    </motion.div>
  </MotionConfig>
);

export const OPTIONS = [
  {
    title: "Accurate Verification",
    Content: () => (
      <Testimonial
        imgSrc="https://img.freepik.com/free-photo/two-doctors-lab_23-2147822684.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
        name="Accurate Verification"
        title="CEO"
        company="Credentialing Services"
        content="Our credentialing process ensures that healthcare providers meet the necessary qualifications and standards, with thorough and accurate verification."
      />
    ),
  },
  {
    title: "Experienced Reviewers",
    Content: () => (
      <Testimonial
        imgSrc="https://img.freepik.com/free-vector/blood-research-concept-illustration_114360-24763.jpg?t=st=1719954685~exp=1719958285~hmac=796ba3b38bdae9308915de0e3a4bd4791914c7fb5e57eeab14ba4006957a0974&w=1800"
        name="Dan S."
        title="Experienced Reviewers"
        company="@CredentialExperts"
        content="Our team of credentialing specialists brings years of experience, ensuring a smooth and comprehensive review process for all applicants."
      />
    ),
  },
  {
    title: "Convenient Online Access",
    Content: () => (
      <Testimonial
        imgSrc="https://img.freepik.com/free-photo/two-doctors-lab_23-2147822684.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
        name="Convenient Online Access"
        title="Writer"
        company="TheCredentialingHub"
        content="Easily access and manage your credentialing documents online, with our user-friendly platform designed for healthcare professionals."
      />
    ),
  },
  {
    title: "Cutting-edge Tools",
    Content: () => (
      <Testimonial
        imgSrc="https://img.freepik.com/free-photo/group-doctors-cooperating-while-examining-xray-patient-hopital_637285-11164.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
        name="Cutting-edge Tools"
        title="Author"
        company="Credentialing Tech"
        content="We use advanced tools and technology to streamline the credentialing process, ensuring efficiency and accuracy at every step."
      />
    ),
  },
  {
    title: "Flexible Scheduling",
    Content: () => (
      <Testimonial
        imgSrc="https://img.freepik.com/free-photo/doctor-is-going-examine-his-patient-using-his-stethoscope-sitting-people_1150-6561.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
        name="Flexible Scheduling"
        title="UI/UX Design"
        company="The Credentialing Company"
        content="We offer flexible scheduling options to accommodate the busy lives of healthcare providers, ensuring the credentialing process fits your timeline."
      />
    ),
  },
  {
    title: "Document Management",
    Content: () => (
      <Testimonial
        imgSrc="https://img.freepik.com/free-vector/blood-research-concept-illustration_114360-24763.jpg?t=st=1719954685~exp=1719958285~hmac=796ba3b38bdae9308915de0e3a4bd4791914c7fb5e57eeab14ba4006957a0974&w=1800"
        name="Document Management"
        title="Consultant"
        company="The Credentialing Experts"
        content="Our platform allows you to easily manage and update your credentialing documents, ensuring that your records are always up-to-date."
      />
    ),
  },
  {
    title: "Quality Assurance",
    Content: () => (
      <Testimonial
        imgSrc="https://img.freepik.com/free-photo/front-view-asian-women-working-together-chemical-project_23-2148776747.jpg?t=st=1719954992~exp=1719958592~hmac=aca523616acc66080469fbcee673438a6c98008479540d75dfe351a03d11686d&w=2000"
        name="Quality Assurance"
        title="Photographer"
        company="@CredentialingQuality"
        content="We perform rigorous quality checks throughout the credentialing process to ensure that all requirements are met with precision."
      />
    ),
  },
  {
    title: "Timely Updates",
    Content: () => (
      <Testimonial
        imgSrc="https://img.freepik.com/free-photo/two-doctors-lab_23-2147822684.jpg?uid=R151749250&ga=GA1.1.386726752.1715621031&semt=ais_user"
        name="Timely Updates"
        title="Film Maker"
        company="@CredentialingPro"
        content="Stay informed with timely updates on your credentialing status, ensuring that you are always aware of where you stand in the process."
      />
    ),
  },
];


import React from 'react'

const Sample = () => {
  return (
    <div>
      <section>
  {/* Container */}
  <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-16">
    {/* Component */}
    <div className="grid grid-cols-1 items-center gap-12 sm:gap-20 lg:grid-cols-2">
      {/* Image Div */}
      <div className="relative left-4 h-full max-h-[560px] w-[85%] md:left-0 md:w-[95%] lg:w-full">
        <img src="https://i.ibb.co/bQLL25Q/3.png" alt="" className="mx-auto block h-full w-full max-w-[800px] rotate-[3.5deg] rounded-2xl object-cover" />
        <div className="absolute bottom-0 left-[-16px] right-0 top-4 -z-10 h-full w-full rounded-2xl bg-black"></div>
      </div>
      {/* Heading Div */}
      <div className="max-w-[720px] max-[991px]:[grid-area:1/1/2/2] lg:max-w-[842px]">
        {/* Heading Text */}
        <div>
          <h2 className="mb-4 text-3xl mt-3  font-semibold md:text-5xl">    <span className=" bg-cover bg-center bg-no-repeat px-4 text-blue-600">Vision</span>  & <span className="  text-blue-600 bg-cover bg-center bg-no-repeat px-4 ">Mission </span> </h2>
          <h3 className="font-bold text-3xl mb-4 ">Vision </h3>
         
          <div className="flex items-start">
    <div className="flex-shrink-0 mt-1 mr-3">
      <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63915fec5d1aa12f6aedd68c_Vector%20(14).svg" alt="Tick" className="w-4 h-4" />
    </div>
    <p className="text-xl text-[#636262]">
    To be the revolutionary platform of choice to ensure smooth revenue flow for healthcare leaders.
    </p>
  </div>
        </div>
        {/* Divider */}
        <div className="mb-6 mt-6 h-0 w-40 border"></div>
        {/* Features */}
        <div className="flex flex-col items-start">
          {/* Item */}
          <h3 className="font-bold text-2xl mb-4">Mission</h3>
<div className="flex flex-col space-y-4">
  <div className="flex items-start">
    <div className="flex-shrink-0 mt-1 mr-3">
      <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63915fec5d1aa12f6aedd68c_Vector%20(14).svg" alt="Tick" className="w-4 h-4" />
    </div>
    <p className="text-xl text-[#636262]">
      To be a healthcare-focused and process-centered brand which develops and delivers innovative, responsible, and quality services to our customers.
    </p>
  </div>

</div>

          {/* Item */}
          <div className="flex items-start">
    <div className="flex-shrink-0 mt-1 mr-3">
      <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63915fec5d1aa12f6aedd68c_Vector%20(14).svg" alt="Tick" className="w-4 h-4" />
    </div>
    <p className="text-xl text-[#636262]">
    At the same time produce predictable earnings for our partners.
    </p>
  </div>
          <div className="flex items-start">
    <div className="flex-shrink-0 mt-1 mr-3">
      <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63915fec5d1aa12f6aedd68c_Vector%20(14).svg" alt="Tick" className="w-4 h-4" />
    </div>
    <p className="text-xl text-[#636262]">
    Also provide a dynamic and challenging environment for our employees.
    </p>
  </div>
         
          {/* Item */}
          {/* <div className="flex items-center">
            <img src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63915fec5d1aa12f6aedd68c_Vector%20(14).svg" alt="" className="mr-2 inline-block h-4 w-4" />
            <p className="text-[#636262]">Lorem ipsum dolor sit amet, consectetur adipiscing</p>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default Sample

import React from "react";
import { CheckPill } from "./CheckPill";
import { OPTIONS } from "./options";

export const Copy = ({ selected, setSelected }) => {
  return (
    <div className="w-full">
      <span className="mb-1.5 block text-center text-indigo-600 md:text-start">
        Credentialing Made Simple
      </span>
      <h2 className="mb-3 text-center text-4xl font-bold leading-tight md:text-start md:text-5xl md:leading-tight">
        Understanding the Credentialing Process
      </h2>
      <p className="mb-6 text-left text-base leading-relaxed md:text-start md:text-lg md:leading-relaxed">
        Credentialing is a vital process that ensures healthcare providers meet
        the necessary standards to deliver quality care. This process involves
        verifying the qualifications, experience, and background of medical
        professionals to ensure they are competent to provide services.
        Understanding credentialing is crucial for maintaining compliance,
        ensuring patient safety, and upholding the integrity of the healthcare
        system.
      </p>
      <div className="mb-6 flex flex-wrap justify-center gap-3 md:justify-start">
        {OPTIONS.map((o, i) => {
          return (
            <CheckPill
              key={o.title}
              index={i}
              selected={i === selected}
              setSelected={setSelected}
            >
              {o.title}
            </CheckPill>
          );
        })}
      </div>
    </div>
  );
};

import React from 'react';

const Team = () => {
  return (
    <div>
      <section className="bg-white px-10">
        <div className="container px-6 py-10 mx-auto">
          <h1 className="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-4xl">
            Our <span className="text-blue-500">Executive Team</span>
          </h1>

          {/* <p className="max-w-2xl mx-auto my-6 text-center text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo incidunt ex placeat modi magni quia error alias, adipisci rem similique, at omnis eligendi optio eos harum.
          </p> */}

          <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-2">
            <div className="px-12 py-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-600 border-gray-200">
              <div className="flex flex-col sm:-mx-4 sm:flex-row">
                <img
                  className="flex-shrink-0 object-cover w-24 h-24 rounded-full sm:mx-4 ring-4 ring-gray-300"
                  src="https://i.ibb.co/TLHH3gT/2.png"
                  alt="Harvey Singh"
                />

                <div className="mt-4 sm:mx-4 sm:mt-0">
                  <h1 className="text-xl font-semibold text-gray-700 capitalize md:text-2xl group-hover:text-white">
                  Harvey Singh
                  </h1>

                  <p className="mt-2 text-gray-500 capitalize group-hover:text-gray-300">
                  Chief Executive Officer
                  </p>
                </div>
              </div>

              <p className="mt-4 text-gray-600  group-hover:text-white">
                Arthur is a visionary design director with a passion for creating impactful user experiences.
              </p>
            </div>
            <div className="px-12 py-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-600 border-gray-200">
              <div className="flex flex-col sm:-mx-4 sm:flex-row">
                <img
                  className="flex-shrink-0 object-cover w-24 h-24 rounded-full sm:mx-4 ring-4 ring-gray-300"
                  src="https://i.ibb.co/QFFt4z4/3.png"
                  alt="Arthur Melo"
                />

                <div className="mt-4 sm:mx-4 sm:mt-0">
                  <h1 className="text-xl font-semibold text-gray-700 capitalize md:text-2xl group-hover:text-white">
                  Emily Garcia
                  </h1>

                  <p className="mt-2 text-gray-500 capitalize group-hover:text-gray-300">
                  Marketing Manager
                  </p>
                </div>
              </div>

              <p className="mt-4 text-gray-600  group-hover:text-white">
                Arthur is a visionary design director with a passion for creating impactful user experiences.
              </p>
            </div>
            <div className="px-12 py-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-600 border-gray-200">
              <div className="flex flex-col sm:-mx-4 sm:flex-row">
                <img
                  className="flex-shrink-0 object-cover w-24 h-24 rounded-full sm:mx-4 ring-4 ring-gray-300"
                  src="https://i.ibb.co/j5yJxDJ/5.png"
                  alt="Soun Kanwar"
                />

                <div className="mt-4 sm:mx-4 sm:mt-0">
                  <h1 className="text-xl font-semibold text-gray-700 capitalize md:text-2xl group-hover:text-white">
                  Soun Kanwar
                  </h1>

                  <p className="mt-2 text-gray-500 capitalize group-hover:text-gray-300">
                  Communications Director
                  </p>
                </div>
              </div>

              <p className="mt-4 text-gray-600  group-hover:text-white">
                Arthur is a visionary design director with a passion for creating impactful user experiences.
              </p>
            </div>
            <div className="px-12 py-8 transition-colors duration-300 transform border cursor-pointer rounded-xl hover:border-transparent group hover:bg-blue-600 border-gray-200">
              <div className="flex flex-col sm:-mx-4 sm:flex-row">
                <img
                  className="flex-shrink-0 object-cover w-24 h-24 rounded-full sm:mx-4 ring-4 ring-gray-300"
                  src="https://i.ibb.co/RhxPbXs/4.png"
                  alt="Arthur Melo"
                />

                <div className="mt-4 sm:mx-4 sm:mt-0">
                  <h1 className="text-xl font-semibold text-gray-700 capitalize md:text-2xl group-hover:text-white">
                  Pramo Virk
                  </h1>

                  <p className="mt-2 text-gray-500 capitalize group-hover:text-gray-300">
                  VP of Europe Operation
                  </p>
                </div>
              </div>

              <p className="mt-4 text-gray-600  group-hover:text-white">
                Arthur is a visionary design director with a passion for creating impactful user experiences.
              </p>
            </div>

            {/* Add more team members here */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar, faHandHoldingUsd, faHeartPulse, faMoneyCheckAlt, faShieldAlt} from "@fortawesome/free-solid-svg-icons";
import { FlyoutNav } from "../Navbar/FlyoutNavbar";
import ShuffleHero  from "../Herosection/ShuffleHero";
import CardCarousel from "../Card/CardCarousel";
import InsuranceAccepted from "./Partners";

const cardData = [
  {
    title: "Comprehensive Billing Services",
    description: "We provide end-to-end medical billing services, ensuring accurate and timely submission of claims. Our expert team handles everything from patient billing to insurance follow-ups, maximizing your revenue and reducing administrative burdens.",
    icon: faFileInvoiceDollar
  },
  {
    title: "Revenue Cycle Management",
    description: "Our revenue cycle management services are designed to optimize your financial performance. We track claims throughout their lifecycle, ensuring that you receive payments as quickly as possible while minimizing denials and rejections.",
    icon: faHandHoldingUsd
  },
  {
    title: "Compliance and Security",
    description: "We prioritize compliance with all healthcare regulations, including HIPAA. Our billing processes are secure, ensuring that your patient data is protected while maintaining compliance with industry standards.",
    icon: faShieldAlt
  },
  {
    title: "Customized Solutions",
    description: "Every practice is unique, and so are our solutions. We offer customized billing services tailored to meet the specific needs of your practice, whether you’re a solo practitioner or a large healthcare provider.",
    icon: faMoneyCheckAlt
  },
];

function MedicalBilling() {
    const [selectedCard, setSelectedCard] = useState(null);

    const handleCardClick = (index) => {
      setSelectedCard(index);
    };
  return (
    <>
      <FlyoutNav/>
      <br></br>
      <br></br>
      <ShuffleHero/>
      <div className="my-6 mt-20 grid grid-cols-1 md:grid-cols-2 mx-2 md:mx-20 gap-10">
        {cardData.map((card, index) => (
          <div
            key={index}
            className={`group mt-5 relative border-2 border-gray-300 rounded-lg p-4 transition-colors duration-300 cursor-pointer ${
              selectedCard === index ? "bg-blue-300 text-white" : "bg-white hover:bg-blue-600 transition-all transform hover: hover:shadow-2xl hover:translate-y-1 active:shadow-inner"
            }`}
            onClick={() => handleCardClick(index)}
          >
              <span className={`absolute top-[-24px] left-1/2 transform -translate-x-1/2 p-2 rounded-full transition-transform duration-700 ${selectedCard === index ? "bg-white rotate-[180]" : "bg-blue-100 group-hover:rotate-[360deg]  "}`}>
              <FontAwesomeIcon className={`${selectedCard === index ? "text-blue-200" : "text-blue-600"} text-2xl`} icon={card.icon} />
            </span>
            <p className={`mt-2 mb-4 font-bold text-2xl font-poppins tracking-wide ${selectedCard === index ? "text-black" : "text-black"}`}>{card.title}</p>
            <p className={`text-lg font-rubik leading-6 tracking-wide ${selectedCard === index ? "text-gray-700" : "text-gray-700"}`}>{card.description}</p>
          </div>
        ))}
      </div>
      <CardCarousel/>
      <InsuranceAccepted/>
    </>
  )
}

export default MedicalBilling

import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';


export default function CredentialCard() {

  const cards = [
    {
      id: 1,
      title: "Streamlined Application Process",
      description:
        "Simplify the complex credentialing process with an easy-to-use platform that guides healthcare professionals through each step, reducing paperwork and administrative burden.",
      imgSrc:
        "https://i.ibb.co/dtMZWwT/8.png",
    },
    {
      id: 2,
      title: "Faster Credentialing Times",
      description:
        "Accelerate the approval process with automated workflows and real-time tracking, ensuring that providers are credentialed and ready to serve patients more quickly.",
      imgSrc:
        "https://i.ibb.co/rmZ9bbT/12.png",
    },
    {
      id: 3,
      title: "Compliance and Accuracy",
      description:
        "Ensure complete compliance with industry standards and maintain accurate records with built-in checks, reducing the risk of errors and rejections.",
      imgSrc:
        "https://i.ibb.co/Bg2Rs0c/9.png",
    },
    {
      id: 4,
      title: "Centralized Document Management",
      description:
        "Keep all necessary documentation in one secure location, making it easy to update, access, and share credentials with relevant parties when needed.",
      imgSrc:
        "https://i.ibb.co/hCVMXXb/10.png",
    },
    {
        id: 5,
        title: "Ongoing Monitoring and Alerts",
        description:
          "Stay informed with continuous monitoring of credentials and receive timely alerts for renewals, expirations, and other critical updates to maintain compliance effortlessly.",
        imgSrc:
          "https://i.ibb.co/qD3TDkg/11.png",
      },
  ];

  const duplicatedCards = [...cards];

  return (
    <div className="overflow-hidden relative mt-4">
    <div className="flex w-fit m-3 md:w-[300%] mx-auto md:animate-moveRight hover-stop  md:flex-row flex-col">
      {duplicatedCards.map((card, index) => (
        <a key={index} href={card.location} className="no-underline">
          <Card className="max-w-[18rem] sm:max-w-[28rem] mx-4 mb-4 sm:mb-0 h-full border-2 flex-col md:flex-row">
          <CardMedia
              component="img"
              className="h-48 object-cover w-full "
              image={card.imgSrc}
              alt="Paella dish"
            />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {card.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {card.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
        </a>
      ))}
    </div>
    </div>
  );
}

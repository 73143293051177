import React from 'react'
import { Discription } from '../components/Discription/Discription'
import { Features } from '../components/Features/Features'
import { Testimonial } from '../components/Testimonial/Testimonial'
import { Stats } from '../components/Stats/Stats'
import { Contact } from '../components/Contact/Contact'
import { DisappearingFeatures } from '../components/Scroll/DisappearingFeatures'
import Example from '../components/Scroll/SwapColumnFeatures'
import { Company } from '../components/Company/ClipPathLinks'
import { Link } from 'react-router-dom'
import SteppedProgress from '../components/Progressfolder/SteppedProgress'
import { SmoothScrollHero } from '../components/SmoothScrollHero/SmoothScrollHero'
import { BenefitsGrid } from "../components/benefits-grid/BenefitsGrid";
import { FeatureGrid } from "../components/grid/FeatureGrid";
import CardCarousel from '../components/Card/CardCarousel'
import { Sampletest } from '../components/AboutUs/Sampletest'
import Sample from '../components/Herosection/Sample/Carousel'
import {Cta, Ctasection} from '../components/CTA/Ctasection'
import { SwipeCarousel } from '../components/Herosection/SwipeCarousel'
import Carousel from '../components/Herosection/Sample/Carousel'
import { Ctasection2 } from '../components/CTA/Ctasection2'
import IndexPage from '../components/CTA/IndexPage'
import  Index  from '../components/CTA/Index'
import VideoCarouselBasicExample from '../components/Herosection/Index'
// import VideoCarouselFullPageExample from '../components/Herosection/CarouselDefault'

const Home = () => {
  return (
    <div>
      {/* <SmoothScrollHero/> */}
        {/* <SteppedProgress/> */}
        <SwipeCarousel/>
        {/* <VideoCarouselBasicExample/> */}
        {/* <Carousel/> */}
        {/* <VideoCarouselBasicExample/> */}
        {/* <Example/> */}
        <Ctasection/>
        <Ctasection2/>
        <IndexPage/>
        <Index/>
        
        <FeatureGrid/>
        <Discription/>
       {/* <CardCarousel/> */}
        {/* <Company/> */}
        {/* <Testimonial/> */}
        {/* <Stats/> */}
        {/* <Contact/> */}
              </div>
  )
}

export default Home

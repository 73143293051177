import React from 'react'
import { NeuHero } from '../Herosection/NeuHero'
import CardCarousel from '../Card/CardCarousel'
import { BenefitsGrid } from '../benefits-grid/BenefitsGrid'

function DenialManagement() {
  return (
    <div>
      <NeuHero/>
      <BenefitsGrid/>
      <CardCarousel/>
    </div>
  )
}

export default DenialManagement

import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function Content() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-3xl text-center grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">We Ensure Accurate Coding</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Medical Coding Solutions</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Our Medical Coding services are designed to streamline healthcare billing, improve accuracy, and ensure compliance with industry standards. We provide top-tier coding solutions that reduce errors and improve the efficiency of your billing process.
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto -mt-12 md:-mt-5 lg:mt-28 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="https://i.ibb.co/rmZ9bbT/12.png"
            alt="Medical Coding"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                My Care Billing offers comprehensive medical coding services that ensure accuracy and compliance with all regulations. Our expert team stays updated with the latest coding standards and practices, providing you with reliable services that enhance your revenue cycle management.
              </p>
              <ul role="list" className="mt-8 space-y-8 text-center text-gray-600">
                <li className="flex gap-x-2">
                  <CloudArrowUpIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Streamlined Process.</strong> Our team simplifies the coding process, ensuring that your practice runs smoothly and efficiently.
                  </span>
                </li>
                <li className="flex gap-x-2">
                  <LockClosedIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Compliance Guaranteed.</strong> We ensure that your coding practices comply with all applicable laws and regulations, reducing the risk of audits and penalties.
                  </span>
                </li>
                <li className="flex gap-x-2">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Advanced Technology.</strong> We use the latest technology to ensure that our coding services are accurate, efficient, and cost-effective.
                  </span>
                </li>
              </ul>
              <p className="mt-8">
                Trust My Care Billing to provide you with medical coding services that support your practice's financial health and compliance. Our expertise in the field ensures that you can focus on what you do best—providing exceptional patient care.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

export const Card = ({ className, children, style = {} }) => {
  return (
    <motion.div
      initial={{
        filter: "blur(2px)",
      }}
      whileInView={{
        filter: "blur(0px)",
      }}
      transition={{
        duration: 0.5,
        ease: "easeInOut",
        delay: 0.25,
      }}
      // style={style}
    
      className={twMerge(
        "relative h-full w-full overflow-hidden rounded-2xl border border-zinc-700 p-6",
        className
      )}
      style={{
        // backgroundImage: `linear-gradient(to bottom right, rgba(117, 151, 233, 0.8), rgba(230, 146, 118, 0.5))`
      }}
    >
      {children}
    </motion.div>
  );
};

import React from 'react'
import { BackgroundBlogCard } from './BlogPostCard'
import { FlyoutNav } from '../Navbar/FlyoutNavbar'
import { ImageTrailHero } from '../Herosection/ImageTrailHero'
import TabsFeatures from '../Tabs/TabsFeatures'
import CardCarousel from '../Card/CardCarousel'
import Features from './Features'

function Arm() {
  return (
    <>
    <FlyoutNav/>
    <ImageTrailHero/>
    <TabsFeatures/>
    <Features/>
    <BackgroundBlogCard/>
    <CardCarousel/>
    </>
  )
}

export default Arm

import React from 'react'
import SwapColumnFeatures from './SwapColumn'
import CredentialCard from '../Credential/card'
import Content from '../Herosection/Content'

function MedicalCoding() {
  return (
    <div>
      <Content/>
      <SwapColumnFeatures/>
      <CredentialCard/>
    </div>
  )
}

export default MedicalCoding

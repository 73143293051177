import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

const VerticalSlideFeatures = () => {
  const [selected, setSelected] = useState(0);

  return (
    <section className="mx-auto flex max-w-5xl flex-col-reverse items-center gap-6 bg-white px-4 py-12 md:flex-row md:gap-12 md:px-8">
      <AnimatePresence mode="wait">
        {basicMetabolicPanelData.map((tab, index) => {
          return selected === index ? (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              key={index}
              className="w-full"
            >
              <Feature {...tab} />
            </motion.div>
          ) : null;
        })}
      </AnimatePresence>
      <Tabs selected={selected} setSelected={setSelected} />
    </section>
  );
};

const Tabs = ({ selected, setSelected }) => {
  return (
    <div className="w-full shrink-0 overflow-scroll md:w-fit">
      {basicMetabolicPanelData.map((tab, index) => {
        return (
          <Tab
            key={index}
            setSelected={setSelected}
            selected={selected === index}
            title={tab.title}
            tabNum={index}
          />
        );
      })}
    </div>
  );
};

const Tab = ({ selected, title, setSelected, tabNum }) => {
  return (
    <div className="group relative w-full md:w-fit">
      <button
        onClick={() => setSelected(tabNum)}
        className="relative z-0 flex w-full border-l-[6px] border-slate-200 p-4 transition-colors group-hover:border-slate-300 md:flex-col md:border-l-8 md:p-6"
      >
        <span
          className={`min-w-[150px] max-w-[200px] text-start text-xl font-bold transition-colors md:text-2xl ${
            selected
              ? "text-violet-500"
              : "text-slate-400 group-hover:text-slate-500"
          }`}
        >
          {title}
        </span>
      </button>
      {selected && (
        <motion.span
          layoutId="vertical-slide-feature-slider"
          className="absolute bottom-0 left-0 top-0 z-10 w-[6px] bg-violet-500 md:w-2"
        />
      )}
    </div>
  );
};

const Feature = ({ title, points, image }) => (
  <div className="flex flex-col md:flex-row items-center w-full">
    <div className="relative h-[250px] w-full md:h-[500px] md:w-1/2 rounded-xl bg-slate-800 shadow-xl">
      {image && (
        <img
          src={image}
          alt={title}
          className="absolute inset-0 h-full w-full object-cover rounded-xl"
        />
      )}
      <div className="absolute top-0 left-0 p-4 bg-black bg-opacity-50 text-white w-full rounded-t-xl">
        <h2 className="text-xl font-bold">{title}</h2>
      </div>
    </div>
    <div className="w-full md:w-1/2 p-6">
      {points.map((point, index) => (
        <div key={index} className="mb-4">
          <h3 className="text-lg font-semibold">{point.heading}</h3>
          <p className="text-slate-600">{point.content}</p>
        </div>
      ))}
    </div>
  </div>
);

export default VerticalSlideFeatures;

const basicMetabolicPanelData = [
  {
    stepNumber: 1,
    title: 'Understanding a Billing Audit',
    points: [
      {
        heading: 'Overview:',
        content: 'A billing audit is a thorough review of a healthcare provider’s billing processes, including documentation, coding, and claims submission, to ensure compliance with regulations and accurate reimbursement.'
      },
      {
        heading: 'Components of a Billing Audit:',
        content: 'The audit examines areas such as patient records, billing codes, claims submissions, and reimbursement amounts to identify discrepancies and ensure compliance.'
      }
    ],
    image: "https://mycarelabs.com/wp-content/uploads/2024/01/image-165.jpg"
  },
  {
    stepNumber: 2,
    title: 'Importance of Billing Audits',
    points: [
      {
        heading: 'Ensuring Compliance:',
        content: 'Billing audits are essential for ensuring that healthcare providers adhere to coding guidelines, payer rules, and legal regulations, which helps avoid penalties.'
      },
      {
        heading: 'Identifying Revenue Opportunities:',
        content: 'Audits can uncover underbilling or missed charges, allowing providers to recover lost revenue and optimize their billing practices.'
      }
    ],
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe9S7GtqKvnS8LPRy_tBFHMFfBNAWuzssNdw&s'
  },
  {
    stepNumber: 3,
    title: 'Preparing for a Billing Audit',
    points: [
      {
        heading: 'Documentation Review:',
        content: 'Ensure that all patient records, billing documents, and coding information are complete, accurate, and easily accessible for the audit.'
      },
      {
        heading: 'Training and Guidance:',
        content: 'Staff should be trained on proper documentation and coding practices. Additionally, they should be informed about the audit process and what to expect.'
      }
    ],
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVU1wwVBhG3gySS0sh5xukZTod9p8BDkqS2Q&s'
  },
  {
    stepNumber: 4,
    title: 'The Billing Audit Process',
    points: [
      {
        heading: 'Data Collection:',
        content: 'Auditors will collect and review a sample of claims, patient records, and billing data to identify patterns or discrepancies.'
      },
      {
        heading: 'Analysis and Reporting:',
        content: 'The collected data is analyzed to check for accuracy in coding, compliance with regulations, and any anomalies that might indicate fraud or errors. Auditors then compile a report of their findings.'
      }
    ],
    image: ""
  },
  {
    stepNumber: 5,
    title: 'Post-Audit Actions',
    points: [
      {
        heading: 'Reviewing Results:',
        content: 'After the audit, the results are reviewed with the healthcare provider to discuss findings, address discrepancies, and plan corrective actions.'
      },
      {
        heading: 'Implementing Changes:',
        content: 'Based on the audit findings, providers should implement changes in their billing practices, including staff retraining, process adjustments, and regular follow-up audits to ensure ongoing compliance.'
      }
    ],
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5IHPcrZxLarXvqvCt3E4XSX-kKQo74F7pmA&s'
  },
];

import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography";
import Avatar from '@mui/material/Avatar';

export function BackgroundBlogCard() {
  const cards = [
    {
      title: "Importance of ARM Management",
      author: "John Doe",
      imageUrl: "https://i.ibb.co/ZJhyFVC/25.png",
      avatarUrl: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8&auto=format&fit=crop&w=1480&q=80",
      description: "Efficient ARM (Accounts Receivable Management) is vital for ensuring a steady cash flow and minimizing the risk of bad debt. Learn how ARM can strengthen your financial health.",
    },
    {
      title: "Key Metrics in ARM Management",
      author: "Jane Smith",
      imageUrl: "https://mycarelabs.com/wp-content/uploads/2023/10/Rectangle-25-2.png",
      avatarUrl: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8&auto=format&fit=crop&w=1480&q=80",
      description: "Explore the essential metrics that every business should track in their ARM processes, including Days Sales Outstanding (DSO) and Collection Effectiveness Index (CEI).",
    },
    {
      title: "Strategies for Effective ARM",
      author: "Emily Johnson",
      imageUrl: "https://mycarelabs.com/wp-content/uploads/2023/10/Rectangle-25-2.png",
      avatarUrl: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8&auto=format&fit=crop&w=1480&q=80",
      description: "Discover strategies to improve your ARM processes, from setting clear credit policies to automating invoicing and payment reminders for enhanced collection efficiency.",
    },
    {
      title: "How ARM Services Can Benefit Your Business",
      author: "Michael Brown",
      imageUrl: "https://mycarelabs.com/wp-content/uploads/2023/10/Rectangle-25-2.png",
      avatarUrl: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8&auto=format&fit=crop&w=1480&q=80",
      description: "Learn how professional ARM services can help you manage your accounts receivable more effectively, reducing administrative burdens and improving cash flow.",
    },
  ];

  return (
    <div className="flex flex-wrap justify-center mt-16 gap-4 p-4 bg-white">
      {cards.map((card, index) => (
        <div key={index} className="relative group h-[30rem] w-full max-w-[20rem] overflow-hidden rounded-xl">
          <Card
            className="relative h-full w-full transition-transform transform group-hover:-translate-y-4"
          >
            <CardHeader
              floated={false}
              shadow={false}
              color="transparent"
              className="absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center transition-opacity duration-300 ease-in-out group-hover:opacity-20"
              style={{ backgroundImage: `url(${card.imageUrl})` }}
            />
            <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-blue-500 via-blue-950 transition-opacity duration-300 ease-in-out group-hover:opacity-50" />
            <CardContent className="relative text-center items-center py-10 px-4 md:px-6">
              <Typography
                variant="h3"
                color="white"
                className="mb-4 text-sm font-medium leading-[1.5] transition-transform duration-300 ease-in-out group-hover:scale-110"
              >
                {card.title}
              </Typography>
              <Avatar
                size="mb"
                variant="circular"
                alt={card.author}
                className="border-2 mx-auto my-1 border-white"
                src={card.avatarUrl}
              />
              <Typography variant="h6" className="mb-2 text-white text-xs">
                {card.description}
              </Typography>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  );
}

import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export function Hero2() {

    const [state, setState] = useState(false);

    // Replace javascript:void(0) path with your path
    const navigation = [
        { title: "Partners", path: "javascript:void(0)" },
        { title: "Customers", path: "javascript:void(0)" },
        { title: "Team", path: "javascript:void(0)" },
    ];

    return (
        <>
            <Helmet>
                <title>Welcome to My Care Billing | Medical Credentialing Services</title>
                <meta name="description" content="Streamline your medical credentialing process with My Care Billing. We provide comprehensive services to ensure healthcare providers meet compliance and accreditation standards." />
                <meta name="keywords" content="medical credentialing, healthcare credentialing, provider credentialing, My Care Billing, compliance, accreditation" />
                <meta name="author" content="My Care Billing" />
            </Helmet>

            <section className="pt-24">
                <div className="max-w-screen-xl mx-auto text-gray-600 gap-x-12 items-center justify-between overflow-hidden md:flex md:px-8">
                    <div className="flex-none space-y-5 px-4 sm:max-w-lg md:px-0 lg:max-w-xl">
                        <h1 className="text-sm text-indigo-600 font-medium">
                            Over 500 Successful Credentialing Cases
                        </h1>
                        <h2 className="text-4xl text-gray-800 font-extrabold md:text-5xl">
                            Medical Credentialing Made Simple
                        </h2>
                        <p>
                            My Care Billing provides comprehensive medical credentialing services to ensure that healthcare providers are fully compliant with industry standards. We help you navigate the complex credentialing process efficiently and effectively.
                        </p>
                        <div className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0">
                            <Link to="/credentialing-services" className="block py-2 px-4 text-center text-white font-medium bg-indigo-600 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none">
                                Get Started
                            </Link>
                            <Link to="/about" className="flex items-center justify-center gap-x-2 py-2 px-4 text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-lg md:inline-flex">
                                Learn More
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="flex-none mt-14 md:mt-0 md:max-w-xl">
                        <img
                            src="https://i.ibb.co/zHDkXss/26.png"
                            className="md:rounded-tl-[108px]"
                            alt="Medical Credentialing"
                        />
                    </div>
                </div>
            </section>
        </>
    );
}

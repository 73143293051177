import { FiMonitor, FiSave, FiSearch } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

const TabsFeatures = () => {
  const [selected, setSelected] = useState(0);

  return (
    <section className="p-4">
      <div className="mx-auto max-w-5xl">
        <Tabs selected={selected} setSelected={setSelected} />

        <AnimatePresence mode="wait">
          {FEATURES.map((tab, index) => {
            return selected === index ? (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                key={index}
              >
                <tab.Feature />
              </motion.div>
            ) : undefined;
          })}
        </AnimatePresence>
      </div>
    </section>
  );
};

const Tabs = ({ selected, setSelected }) => {
  return (
    <div className="flex overflow-x-scroll">
      {FEATURES.map((tab, index) => {
        return (
          <Tab
            key={index}
            setSelected={setSelected}
            selected={selected === index}
            Icon={tab.Icon}
            title={tab.title}
            tabNum={index}
          />
        );
      })}
    </div>
  );
};

const Tab = ({ selected, Icon, title, setSelected, tabNum }) => {
  return (
    <div className="relative w-full">
      <button
        onClick={() => setSelected(tabNum)}
        className="relative z-0 flex w-full flex-row items-center justify-center gap-4 border-b-4 border-slate-200 bg-white p-6 transition-colors hover:bg-slate-100 md:flex-col"
      >
        <span
          className={`rounded-lg bg-gradient-to-br from-indigo-700 from-10% to-indigo-500 p-3 text-2xl text-white shadow-indigo-400 transition-all duration-300 ${
            selected
              ? "scale-100 opacity-100 shadow-lg"
              : "scale-90 opacity-50 shadow"
          }`}
        >
          <Icon />
        </span>
        <span
          className={`min-w-[150px] max-w-[200px] text-start text-xs text-slate-600 transition-opacity md:text-center ${
            selected ? "opacity-100" : "opacity-50"
          }`}
        >
          {title}
        </span>
      </button>
      {selected && (
        <motion.span
          layoutId="tabs-features-underline"
          className="absolute bottom-0 left-0 right-0 z-10 h-1 bg-indigo-600"
        />
      )}
    </div>
  );
};

// Feature components with ARM Management content
const Feature1 = () => (
  <div className="flex flex-col md:flex-row items-center p-4 text-left">
    <div className="w-full md:w-1/2  md:ml-4 mb-4 md:mb-0">
      <h2 className="text-2xl font-bold mb-4">1. Importance of ARM Management</h2>
      <p className="mb-4">
        Effective Accounts Receivable Management (ARM) is critical for maintaining a healthy cash flow. 
        It ensures timely collection of payments, reduces the risk of bad debts, and improves the overall financial stability of your organization.
      </p>
    </div>
    <div className="w-full md:w-1/2">
      <ExampleFeature Icon={FiSearch} />
    </div>
  </div>
);

const Feature2 = () => (
  <div className="flex flex-col md:flex-row-reverse items-center p-4 text-left">
    <div className="w-full md:w-1/2 md:ml-4 mb-4 md:mb-0">
      <h2 className="text-2xl font-bold mb-4">2. Benefits of Professional ARM Services</h2>
      <p className="mb-4">
        Utilizing professional ARM services can streamline your billing processes, 
        improve the efficiency of collections, and reduce the administrative burden on your staff. 
        It helps in maintaining positive client relationships by ensuring that all billing is accurate and timely.
      </p>
    </div>
    <div className="w-full md:w-1/2">
      <ExampleFeature2 Icon={FiSave} />
    </div>
  </div>
);

const Feature3 = () => (
  <div className="flex flex-col md:flex-row items-center p-4">
    <div className="w-full md:w-1/2  md:ml-4 mb-4 md:mb-0">
      <h2 className="text-2xl font-bold mb-4">3. Key Metrics in ARM Management</h2>
      <p className="mb-4">
        Monitoring key metrics such as Days Sales Outstanding (DSO), Collection Effectiveness Index (CEI), and Bad Debt Ratio is crucial in assessing the performance of your ARM processes. 
        Regular analysis of these metrics can help in identifying areas of improvement and optimizing your accounts receivable strategies.
      </p>
    </div>
    <div className="w-full md:w-1/2">
      <ExampleFeature3 Icon={FiMonitor} />
    </div>
  </div>
);

const Feature4 = () => (
  <div className="flex flex-col md:flex-row-reverse items-center p-4 text-left">
    <div className="w-full md:w-1/2 md:ml-4 mb-4 md:mb-0">
      <h2 className="text-2xl font-bold mb-4">4. Strategies for Effective ARM</h2>
      <p className="mb-4">
        Implementing effective ARM strategies such as setting clear credit policies, 
        utilizing automated reminders, and offering multiple payment options can significantly improve your collection rates. 
        These strategies ensure that your cash flow remains steady and that your accounts receivable are managed efficiently.
      </p>
    </div>
    <div className="w-full md:w-1/2">
      <ExampleFeature4 Icon={FiSave} />
    </div>
  </div>
);

const Feature5 = () => (
  <div className="flex flex-col md:flex-row items-center p-4">
    <div className="w-full md:w-1/2  md:ml-4 mb-4 md:mb-0">
      <h2 className="text-2xl font-bold mb-4">5. How Our ARM Services Can Help</h2>
      <p className="mb-4">
        Our comprehensive ARM services are designed to help businesses manage their accounts receivable effectively. 
        We provide tailored solutions that include credit management, invoicing, payment processing, and collections, 
        ensuring that your financial operations run smoothly and that you have access to the cash flow you need to grow your business.
      </p>
    </div>
    <div className="w-full md:w-1/2">
      <ExampleFeature5 Icon={FiMonitor} />
    </div>
  </div>
);

const ExampleFeature = ({ Icon }) => (
  <div className="w-full px-0 py-8 md:px-8">
    <img className="lg:rounded-t-lg sm:rounded-sm xs:rounded-sm h-70 w-96" src="https://img.freepik.com/free-vector/businesspeople-analyzing-graph-illustration_74855-7105.jpg" alt="business analysis" />
  </div>
);

const ExampleFeature2 = ({ Icon }) => (
  <div className="w-full px-0 py-8 md:px-8">
    <img className="lg:rounded-t-lg sm:rounded-sm xs:rounded-sm h-70 w-96" src="https://img.freepik.com/free-vector/financial-analysis-concept-illustration_114360-258.jpg" alt="financial analysis" />
  </div>
);

const ExampleFeature3 = ({ Icon }) => (
  <div className="w-full px-0 py-8 md:px-8">
    <img className="lg:rounded-t-lg sm:rounded-sm xs:rounded-sm h-70 w-96" src="https://img.freepik.com/free-vector/people-analyzing-growth-charts_23-2148904183.jpg" alt="data analysis" />
  </div>
);

const ExampleFeature4 = ({ Icon }) => (
  <div className="w-full px-0 py-8 md:px-8">
    <img className="lg:rounded-t-lg sm:rounded-sm xs:rounded-sm h-70 w-96" src="https://img.freepik.com/free-vector/teamwork-business-brainstorming_74855-6883.jpg" alt="teamwork" />
  </div>
);

const ExampleFeature5 = ({ Icon }) => (
  <div className="w-full px-0 py-8 md:px-8">
    <img className="lg:rounded-t-lg sm:rounded-sm xs:rounded-sm h-70 w-96" src="https://img.freepik.com/free-vector/business-meeting-teamwork-working-together-office-table_1150-37311.jpg" alt="teamwork" />
  </div>
);

const FEATURES = [
  {
    title: "Importance of ARM Management",
    Icon: FiSearch,
    Feature: Feature1,
  },
  {
    title: "Benefits of Professional ARM Services",
    Icon: FiSave,
    Feature: Feature2,
  },
  {
    title: "Key Metrics in ARM Management",
    Icon: FiMonitor,
    Feature: Feature3,
  },
  {
    title: "Strategies for Effective ARM",
    Icon: FiSave,
    Feature: Feature4,
  },
  {
    title: "How Our ARM Services Can Help",
    Icon: FiMonitor,
    Feature: Feature5,
  },
];

export default TabsFeatures;

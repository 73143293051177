  import React from 'react'
  import { motion } from "framer-motion";
  import { useState } from "react";
  import { FiChevronLeft,
    FiChevronRight,} from "react-icons/fi";

  const CollapseCardFeatures = () => {
    const [position, setPosition] = useState(0);

    const shiftLeft = () => {
      if (position > 0) {
        setPosition((pv) => pv - 1);
      }
    };

    const shiftRight = () => {
      if (position < features.length - 1) {
        setPosition((pv) => pv + 1);
      }
    };

    return (
      <section className="overflow-hidden  mt-16 px-4 py-12">
        <div className="mx-auto max-w-6xl">
          <div className="mb-8 flex justify-between gap-4">
            <h2 className="text-4xl font-bold leading-[1.2] md:text-5xl">
              We're good. <span className="text-neutral-400">Here's why.</span>
            </h2>
            <div className="flex gap-2">
              <button
                className="h-fit bg-black p-4 text-2xl text-white transition-colors hover:bg-neutral-700"
                onClick={shiftLeft}
              >
                <FiChevronLeft />
              </button>
              <button
                className="h-fit bg-black p-4 text-2xl text-white transition-colors hover:bg-neutral-700"
                onClick={shiftRight}
              >
                <FiChevronRight />
              </button>
            </div>
          </div>
          <div className="flex gap-4">
            {features.map((feat, index) => (
              <Feature {...feat} key={index} position={position} index={index} />
            ))}
          </div>
        </div>
      </section>
    );
  };

  const Feature = ({ position, index, title, description, iconSrc }) => {
    const translateAmt =
      position >= index ? index * 100 : index * 100 - 100 * (index - position);

    return (
      <motion.div
        animate={{ x: `${-translateAmt}%` }}
        transition={{
          ease: "easeInOut",
          duration: 0.35,
        }}
        className={`relative flex min-h-[250px] w-10/12 max-w-lg shrink-0 flex-col justify-between overflow-hidden p-8 shadow-lg md:w-3/5 ${
          index % 2 ? "bg-black text-white" : " bg-white"
        }`}
      >
        <img src={iconSrc} alt={title} className="absolute right-2 top-2 w-16 h-16 opacity-100" />
        <h3 className="mb-8 text-3xl font-bold">{title}</h3>
        <p>{description}</p>
      </motion.div>
    );
  };

  export default CollapseCardFeatures;

  const features = [
    {
      title: "On Demand",
      iconSrc: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrUc-cAjLTSr4KfkbseHVM8Btu7oTdqOGYQA&s",
      description:
        "Access our billing audit services whenever you need them, ensuring your financial processes are always compliant and error-free. Our on-demand services are available to address any billing discrepancies quickly and efficiently.",
    },
    {
      title: "Team On Location",
      iconSrc: "https://png.pngtree.com/png-vector/20191024/ourlarge/pngtree-team-icon-isolated-on-abstract-background-png-image_1855162.jpg",
      description:
        "Our professional audit team can be deployed to your location, providing hands-on support to review and rectify your billing processes. We work closely with your staff to identify and correct any financial inconsistencies.",
    },
    {
      title: "Medical Assistant",
      iconSrc: "https://media.istockphoto.com/id/1384892983/vector/first-aid-icon-healthcare-and-medicine-humanitarian-aid-medical-cross-symbol.jpg?s=612x612&w=0&k=20&c=Ki3lHkF4eFxUbMdYAWfR1rtuKDao57rRB6EWhk_XyMc=",
      description:
        "Our billing audit services include support from experienced medical assistants who understand the complexities of healthcare billing. They ensure that your billing is accurate, compliant, and reflective of the services provided.",
    },
    {
      title: "No Cost With Insurance",
      iconSrc : "https://as1.ftcdn.net/v2/jpg/02/73/32/80/1000_F_273328038_pBxXai6PVN1eLXnH65ZVgy954nzAEMSt.jpg",
      description:
        "Enjoy our billing audit services at no additional cost when covered by your insurance. We collaborate with your insurance providers to ensure that audit services are a seamless part of your coverage.",
    },
    {
      title: "Billing Audit",
      iconSrc: "https://example.com/path-to-your-icon.jpg", // Replace with your actual icon URL
      description:
        "Our comprehensive billing audit service meticulously reviews your financial records to ensure accuracy and compliance. We identify errors, prevent fraud, and ensure that all billing is aligned with industry standards, giving you peace of mind and financial security.",
    }
  ];

